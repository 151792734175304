<template>
  <div class='live-chat-container' @click="closeQuestion">
      <div class="live-chat-content">
        <item-scroll ref="scroll" :useTransition="true">
          <div class="live-chat-li" :style="{'color':getColor(item)}" v-for="(item,index) in chatList" :key="index" >
            <div class="live-chat-name">
            <p>{{ item.name }}</p>
            <p>：</p>
            </div>
            <div class="live-chat-record" v-html="item.content"></div>
          </div>
        </item-scroll>
      </div>
      <div class="live-chat-button " v-if="$tools.isGuest(meetingInfo)" @click.stop="closeFocus">
        <div class="global_text_input">
          <!-- <el-select v-model="message" placeholder="请选择问题提问">
            <el-option v-for="item in meetingInfo?.class_info.question || [] " :key="item" :label="item.question" :value="item.question"></el-option>
          </el-select> -->
          <el-input ref="questionInput" v-model="message"  placeholder="请选择或输入" @focus="onFocus"/>
        </div>
        <CustomButton type="primary" @click.stop="sendQuestion">发送</CustomButton>
      </div>
      <div class="question_popup_container" v-show="showQuestion" @click.stop="">
        <div class="question_banner_container">
          <div class="question_banner" :class="{global_primary_text_color:!selectIndex}" @click.stop="selectQueType(0)">
            <div :class="`${selectIndex == 0 ? 'question-popup-banner-active': ''} `">互动问题</div>
          </div>
          <div class="question_popup_separator"></div>
          <div class="question_banner" :class="{global_primary_text_color:selectIndex}" @click.stop="selectQueType(1)">
            <div :class="`${selectIndex == 1 ? 'question-popup-banner-active': ''} `">常用语</div>
          </div>
        </div>
        <div class="question_scroll" >
          <item-scroll ref="question_scroll">
            <div v-if="this.selectIndex==0">
              <div v-for="(item,key) in questionList" :key="key" @click="selectQuestionType(item)">
                <div class="question_item_type border_bottom">
                  <div>
                    {{numberToChinese(key+1)}}、{{ item.type }}
                  </div>
                  <div>
                    <el-icon v-if="currentQuestionType == item.type"><ArrowUpBold /></el-icon>
                    <el-icon v-else><ArrowDownBold /></el-icon>
                  </div>
                </div>
                <div class="question_text" v-show="currentQuestionType == item.type">
                  <div v-for="(question,index) in item.question" :key="index" class="border_bottom" @click.stop="selectQuestion(question)">
                    {{ `${index+1}、 ${question}` }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-for="(val,ind) in commconList" :key="ind" class="question_item border_bottom" @click.stop="selectQuestion(val)">
                {{ val }}
              </div>
            </div>
          </item-scroll>
        </div>
      </div>
  </div>
</template>
<script>
import { ArrowUpBold,ArrowDownBold } from "@element-plus/icons"
import ItemScroll from '@/components/unit/ItemScroll'
import CustomButton from '@/components/unit/CustomButton'
export default {
  name:'live-chat',
  props:{
      meetingInfo:{
          type:Object,
          default:()=>{
              return { }
          }
      },
      isLive:{
        type:Boolean,
        default:false
        }
  },
  components:{
    ItemScroll,
    CustomButton,
    ArrowUpBold,
    ArrowDownBold
  },
  data:()=>{
      return {
        message:"",
        selectIndex:0,
        showQuestion:false,
        currentQuestionType:""
      }
  },
  computed:{
    commconList(){
      return this.meetingInfo?.word_commcon || []
    },
    questionList(){
      let list = []
      this.meetingInfo?.class_info?.question?.forEach(x=>{
        let item = list.find(e=>e.type==x.type)
        if(!item) {
          list.push({type:x.type,question:[x.question]})
        }else{
          item.question.push(x.question)
        }
      })
      return list;
    },
    chatList(){
      if(!this.isLive) return []
      let chatList = this.$tools.getChatList(this.meetingInfo)
      chatList = chatList.filter(item=>item.content_type != 1);
      let tempChatList = JSON.parse(JSON.stringify(chatList))
      let commonList = this.meetingInfo.word_commcon || []
      commonList = commonList.concat(this.generatCommonList())
      for (let index = 0; index < chatList.length; index++) {
        const element = chatList[index];
        if(!commonList.includes(element.content) && element.id){
          let i = tempChatList.findIndex(e=>e.id==element.id)
          if(i>-1){
            let systemChat = {
              name: "系统",
              content: "本互动不提供涉及药品或诊疗相关的任何专业咨询，如对于药品或诊疗相关的任何问题须咨询专业医生。",
              color:"gray"
            };
            tempChatList.splice(i+1,0,systemChat)
          }
        }
      }
      return tempChatList
    }
  },
  created(){
    this.initEnter();
  },
  methods:{
    generatCommonList(){
      let list = ["教授好","老师好","老师，早上好","教授，早上好","教授好","老师好","老师，下午好","教授，下午好","教授好","老师好","老师，晚上好","教授，晚上好"]
      let result = []
      list.forEach(val=>{
        result.push(`${this.meetingInfo?.meet_info?.doctor_name || ""}${val}`)
      })
      return result
    },
      initScroll(){
        this.$nextTick(()=>{
          this.$refs.scroll.initScroll(true);
        })
      },
      initQuestionScroll(){
        this.$nextTick(()=>{
          this.$refs.question_scroll.initScroll();
        })
      },
      selectQueType(index){
        this.selectIndex = index;
        this.initQuestionScroll();
      },
      selectQuestionType(questionItem){
        if(questionItem.type == this.currentQuestionType){
          this.currentQuestionType = "";
        }else{
          this.currentQuestionType = questionItem.type;
        }
        this.initQuestionScroll()
      },
      selectQuestion(question){
        this.message = question;
        this.closeQuestion()
      },
      getColor(item){
        // console.log(item)
          if(item.color) return item.color;
          if(item.openid == this.meetingInfo.openid) return this.$config.color
          return this.$tools.getOpenid() == item.openid ? '#1588f5' : '#000'
      },
      sendQuestion(){
          if(this.message == "") return
          this.talk()
          this.closeQuestion()
      },
      isSelf(item){
          return this.info.openid === item.openid 
      },
      initEnter(){
          document.onkeydown = () => {
              var key = window.event.keyCode;
              if (key == 13) {
                  this.talk();
              }
          }
      },
      focusInput(){
          this.showFastMessage = false;
          this.initEnter();
      },
      talk(){
        let content = this.message
        this.message = ""
        let data = this.$tools.getChatContent(content,this.meetingInfo.show_name)
        this.$emit('sendMessage',data)
      },
      onFocus(){
        setTimeout(()=>{
          this.focus = true
        },0)
        this.showQuestion = true;
        if(this.showQuestion) this.initQuestionScroll();
        // this.$refs.questionInput.blur();
      },
      closeFocus(){
        if(this.focus){
          this.showQuestion = !this.showQuestion;
        }
      },
      closeQuestion(){
        this.focus = false
        this.showQuestion = false
      },
      numberToChinese(num) {
        const chineseNums = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
        const chineseUnits = ['', '十', '百', '千'];
        if (num === 0) {
          return chineseNums[0];
        }
      
        let chineseStr = '';
        let unitIndex = 0;
      
        while (num > 0) {
          const digit = num % 10;
          if (digit !== 0) {
            // 处理非零数字
            chineseStr = chineseNums[digit] + chineseUnits[unitIndex] + chineseStr;
          } else if (chineseStr.charAt(0) !== chineseNums[0]) {
            // 处理连续的零，只保留一个零
            chineseStr = chineseNums[0] + chineseStr;
          }
      
          num = Math.floor(num / 10);
          unitIndex++;
        }
        return chineseStr;
      }
  }
}
</script>
<style >
.live-chat-container{
  height: 100%;
  width:100%;
  display: flex;
  flex-direction: column;
  padding:4vw 0 1vw 0;
}
.live-chat-content{
  flex:1 0 0;
  position: relative;
  overflow: hidden;
}
.live-chat-li{
  display: flex;
  line-height: 6.4vw;
}
.live-chat-name{
  flex:17vw 0 0;
  /* word-break: keep-all; */
  padding-left: 4vw;
  display: flex;
    justify-content: space-between;
}
.live-chat-record{
  padding-right: 4vw;
}
.live-chat-button{
  display: flex;
  align-items: center;
  padding: 0 4vw;
}
.live-chat-button .customButton_container{
  height:8.8vw !important;
}
.live-chat-button .global_text_input{
  padding:0;
}
.live-chat-button input::placeholder{
  /* color:transparent */
}
.live-chat-button .el-input__inner{
  padding-left:4vw;
}
.el-select-dropdown__item.hover .el-select-dropdown__item:hover {
    background-color: #fff !important;
}
.question_popup_container{
  width: 100%;
  height: calc(100% - 8.8vw - 12vw);
  /* background-color: #3b3b3b49; */
  position: absolute;
  left: 0;
  bottom: 12vw;
  padding: 0 4vw;
  display: flex;
  flex-direction: column;
  z-index: 99999;
}
.question_banner_container{
  width: 100%;
  flex: 8vw 0 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.question_banner{
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
.question_popup_separator{
  height: 60%;
  border-left: 1px solid #868686;
}
.question-popup-banner-active{
  position: relative;
}
.question-popup-banner-active::after{
  content: "";
  display: inline-block;
  width:100%;
  height:0.5vw;
  position: absolute;
  bottom:calc(-1.27vw);
  right:0;
  background:#008f3d;
  border-radius:0.5vw;
}
.question_scroll{
  padding: 2vw 4vw;
  overflow: hidden;
  flex: 1 0 0;
  width: 100%;
  background-color: #fff;
}
.question_item{
  line-height: 8vw;
}
.border_bottom{
  border-bottom: 1px solid #e2e2e2;
}
.question_item_type{
  line-height: 10vw;
  display: flex;
  justify-content: space-between;
}
.question_text{
  padding: 2vw 4vw;
}
.question_text div{
  min-height: 10vw;
  display: flex;
  align-items: center;
  padding: 2vw 0;
}
</style>